import mainHero from '../../assets/images/mainHero.svg';
import ourGoalRocket from '../../assets/images/ourGoalRocket.svg';
import howItWorks_1 from '../../assets/images/howItWorks_1.svg';
import howItWorks_2 from '../../assets/images/howItWorks_2.svg';
import howItWorks_3 from '../../assets/images/howItWorks_3.svg';
import howItWorks_4 from '../../assets/images/howItWorks_4.svg';
import howItWorks_5 from '../../assets/images/howItWorks_5.svg';
import opportunities from '../../assets/images/opportunities.svg';
import platform_1 from '../../assets/images/platform_1.svg';
import platform_2 from '../../assets/images/platform_2.svg';
import platform_3 from '../../assets/images/platform_3.svg';
import platform_4 from '../../assets/images/platform_4.svg';
import platform_5 from '../../assets/images/platform_5.svg';
import platform_6 from '../../assets/images/platform_6.svg';
import { PHONE_NUMBER, PHONE_NUMBER_TEL } from '../../constants/texts';

const content = {
  heroContent: {
    mainHeading:
      'Запустіть продаж онлайн сертифікатів для вашого бізнесу за один день',
    subHeading:
      'Простий спосіб створити новий канал продажу, збільшити прибуток та залучити нових клієнтів.',
    buttonLabel: 'Створити сертифікат',
    createButtonLabel: 'Створити кампанію',
    image: mainHero,
  },
  benefits: {
    title: 'Ви отримаєте',
    list: [
      'Сторінку власного white label сайту для продажу онлайн сертифікатів ваших послуг',
      'Підключення онлайн платежів',
      'Повну автоматизацію процесу продажу та списання сертифікатів',
      'Автоматичні сповіщення для покупців на їх електронну адресу',
      'Систему обліку та управління проданими сертифікатами ',
      'Наша технічна підтримка попіклується про те, щоб усі ваші клієнти гарантовано отримали свої подарунки вчасно',
    ],
  },
  pricing: {
    title: 'Тарифи',
    terms: 'За використання платформи ви сплачуєте лише комісію',
    highlightedTerms: 'з кожного проданого сертифіката',
    selfCommission: 'Комісія Upfront - 5%',
    paymentCommission: 'Комісія платіжної системи - 2,75%',
    advantages:
      'Це дешевше самостійного створення сайту і значно простіше в налаштуванні. Ви сплачуєте комісію лише тоді, коли відбувається продаж сертифіката.',
    demo: 'демо.',
    description: 'Не знаєте як буде виглядати сторінка? Переглянути ',
    demoLink: 'https://upfront.com.ua/campaign/5efb40576366b92f8fa7e768',
  },
  ourGoal: {
    title: 'Наша мета',
    subTitle:
      'Наша мета — надати підприємцям легкий доступ до продажу електронних сертифікатів, а також зробити процес покупки та використання зручним для їхніх клієнтів.',
    info:
      'Ми створили інструмент, за допомогою якого будь-який бізнес може здійснювати продаж товарів чи послуг в інтернеті, створювати або розширювати власну онлайн аудиторію та диверсифікувати джерела доходу. ',
    image: ourGoalRocket,
  },
  howItWorks: {
    title: 'Як це працює',
    steps: [
      {
        number: 1,
        title: 'Реєстрація',
        text:
          'Ви створюєте обліковий запис в сервісі Upfront та підключаєте платіжну систему.',
        image: howItWorks_1,
      },
      {
        number: 2,
        title: 'Налаштування кампанії',
        text:
          'Додаєте в системі лого та фото вашого бізнесу, брендовий колір, вказуєте ціну сертифікатів, їх опис, дату активації та термін дії.',
        image: howItWorks_2,
      },
      {
        number: 3,
        title: 'Поширення',
        text:
          'Система створить індивідуалізовану веб-сторінку. Поширюйте її у соціальних мережах, додайте в Google Мій бізнес, здійснюйте розсилки електронною поштою, запускайте рекламні кампанії.',
        image: howItWorks_3,
      },
      {
        number: 4,
        title: 'Отримання коштів',
        text:
          'Після продажу, сертифікати будуть надіслані вашим клієнтам на електронну пошту, а кошти будуть зараховані на ваш банківський рахунок протягом 24 годин.',
        image: howItWorks_4,
      },
      {
        number: 5,
        title: 'Використання сертифікатів',
        text:
          'Сертифікати можна використати називаючи його код або за допомогою QR-коду. Якщо сертифікат передбачає часткове списання вартості, при використанні неповної вартості сертифікату залишок не згорає. Ним можна скористатись при наступній покупці.',
        image: howItWorks_5,
      },
    ],
  },
  opportunities: {
    title: 'Можливості',
    image: opportunities,
    list: [
      {
        text:
          'Продаж подарункових сертифікатів онлайн в електронній формі для збільшення клієнтської бази та збільшення лояльності клієнтів',
      },
      {
        text:
          'Продаж ваучерів або купонів зі знижкою на послуги для стрімкого розвитку вашого бізнесу',
      },
      {
        text:
          'Продаж абонементів на визначену кількість візитів онлайн з власного white-label сайту',
      },
      {
        text: 'Попередній продаж сертифікатів з встановленою датою активації',
      },
      {
        text:
          'Залучення коштів на фінісування бізнесу в період карантину від ваши лояльних клієнтів',
      },
      {
        text:
          'Одночасний запуск будь-якої кількості веб-сторінок з різними пропозиціями',
      },
      {
        text:
          'Повна автоматизація процесу продажу та списання сертифікатів та ваучерів',
      },
    ],
  },
  platform: {
    title: 'Особливості платформи',
    list: [
      {
        image: platform_2,
        text:
          'Одночасний запуск декількох кампаній з різними умовами сертифікатів',
      },
      {
        image: platform_3,
        text:
          'Встановлення розміру знижки для кожного сертифіката - визначити номінальну вартість та ціну продажу',
      },
      {
        image: platform_1,
        text: 'Проста інтеграція з платіжною системою',
      },
      {
        image: platform_4,
        text:
          'Сторінка та повідомлення у вашому фірмовому стилі, повна підтримка мобільних пристроїв',
      },
      {
        image: platform_5,
        text:
          'Користувачі можуть легко подарувати сертифікат друзям або колегам',
      },
      {
        image: platform_6,
        text: 'Зручне управління сертифікатами: перегляд, облік, використання',
      },
    ],
  },
  ourClients: {
    title: 'Наші клієнти',
    list: [],
    buttonLabel: 'Зареєструватись',
    createButtonLabel: 'Створити кампанію',
  },
  contact: {
    title: 'Маєте запитання?',
    subTitle: 'Залиште заявку для персональної консультації про наш сервіс',
  },
  footer: {
    address: 'м. Київ, Кловський узвіз 7А, 02132',
    tel: PHONE_NUMBER,
    hrefTel: PHONE_NUMBER_TEL,
    email: 'upfront.com.ua@gmail.com',
    policy: 'Політика конфіденційності',
    faq: 'FAQ',
    agrement: 'Публічна оферта',
    telegram: 'https://t.me/upfront_support',
  },
};

export default content;
