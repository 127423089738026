import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import styles from './Terms.module.scss';

export default function Terms() {
  return (
    <div className={styles.terms}>
      <h1>Політика конфіденційності Upfront</h1>
      <p>
        <b>Дата опублікування: 27.05.2020</b>
      </p>
      <p>
        Ця Політика конфіденційності (далі – «Політика») є невід’ємною частиною{' '}
        <Link to={ROUTES.AGREEMENT} target="_blank" rel="noopener noreferrer">
          Публічної оферти Upfront
        </Link>
        .
      </p>
      <p>
        Ця Політика регулює порядок обробки персональних даних Користувачів
        («Користувач(і)», а також «ви») вебсайту{' '}
        <a
          target="_blank"
          href="https://upfront.com.ua"
          rel="noopener noreferrer"
        >
          https://upfront.com.ua
        </a>{' '}
        («Вебсайт») фізичною особою-підприємцем Чижем Романом Вікторовичем, який
        діє під комерційним найменуванням Upfront («Upfront», а також «ми»).
        Зокрема, вона визначає:
      </p>
      <ul>
        <li>які саме персональні дані обробляє Upfront;</li>
        <li>які цілі такої обробки;</li>
        <li>які права має Користувач стосовно таких даних;</li>
        <li>чи передаються дані третім особам;</li>
        <li>яких заходів вживає Upfront для захисту персональних даних;</li>
        <li>а також інші деталі обробки персональних даних.</li>
      </ul>
      <p>
        Upfront турбується про приватність своїх Користувачів. У процесі обробки
        персональних даних Upfront дотримується усіх положень, визначених
        Законом України «Про захист персональних даних».
      </p>
      <p>
        Крім цього, наші юристи доклали усіх зусиль, щоб зробити цю Політику
        максимально зрозумілою для Користувачів. Однак, якщо у вас виникнуть
        будь-які запитання щодо цієї Політики, обробки ваших персональних даних
        сервісом Upfront чи реалізації ваших прав як суб’єкта персональних
        даних, просто надішліть нам листа на цю адресу:{' '}
        <a href="mailto:data@upfront.com.ua">data@upfront.com.ua</a>.
      </p>
      <h2>1. Що таке персональні дані?</h2>
      <p>
        <b>Персональними даними</b> є відомості чи сукупність відомостей про
        вас, які дають можливість для особи, яка збирає та обробляє таку
        інформацію, ідентифікувати вас. Персональними даними, зокрема, є ваше
        ім’я, ідентифікаційний номер, дані про місцезнаходження чи інша
        інформація про вас.
      </p>
      <p>
        <b>Обробкою персональних даних</b> вважається будь-яка дія або
        сукупність дій з вашими персональними даними, зокрема, збирання,
        реєстрація, накопичення, зберігання, адаптування, зміна, поновлення,
        використання і поширення, знеособлення, знищення персональних даних.
      </p>
      <p>
        Мета обробки, а також склад персональних даних та процедури їхньою
        обробки визначається <b>володільцем персональних даних</b>.
      </p>
      <p>
        <b>Володільцем даних, які збираються через Вебсайт, є:</b>
      </p>
      <blockquote>
        <b>ФОП Чиж Роман Вікторович</b>,<br />
        Адреса: 02088, м. Київ, вул. Садова, 8, буд. 18
        <br />
        2 072 000 0000 039827
        <br />
      </blockquote>
      <p>
        Інші терміни у цій Політиці вживаються у значенні, наданому їм у
        Публічній оферті.
      </p>
      <h2>2. Які персональні дані обробляє Upfront та чому?</h2>
      <p>
        Обсяг персональних даних, які можуть збиратися через Вебсайт, залежить
        від того, з якою метою ви використовуєте Вебсайт:
      </p>
      <table>
        <thead>
          <tr>
            <th>Категорія Користувачів</th>
            <th>Обсяг персональних даних та мета їхньої обробки</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Покупців Сертифікатів</td>
            <td>
              <p>
                <b>
                  Для того, щоб надати вам можливість придбати та згодом
                  використати Сертифікат
                </b>
                , ми збираємо та обробляємо ваше ім’я та прізвище, а також
                адресу електронної пошти, на яку ми доставляємо Сертифікат після
                його оплати.
              </p>
              <p>
                Ми також можемо використовувати такі ваші персональні дані для
                того,{' '}
                <b>щоб повідомляти вам важливу інформацію щодо Сертифікатів</b>,
                наприклад, щоб нагадати вам про невикористаний Сертифікат, про
                наближення дати закінчення його терміну дії чи дату його
                активації.
              </p>
              <p>
                <b>Зверніть увагу</b>, що дані Покупців Сертифікатів також{' '}
                <b>доступні для Корпоративних клієнтів</b>, що є емітентами
                відповідних Сертифікатів, адже саме вони продають ці Сертифікати
                та беруть на себе зобов’язання згодом обміняти їх на свою
                Продукцію. Корпоративні клієнти можуть обробляти такі дані для
                того, щоб{' '}
                <b>
                  виконати укладений з вами договір, а також щоб у майбутньому
                  запропонувати вам свою Продукцію чи ознайомити вас із іншою
                  інформацією, що на їхню думку, може вас зацікавити
                </b>
                . Для цього вони, наприклад, час від часу можуть надсилати листи
                на вашу електронну пошту. Ви можете відмовитися від отримання
                таких листів у будь-який час.
              </p>
              <p>
                Корпоративні клієнти, що є емітентами Сертифікатів, разом із
                Upfront є володільцями даних Покупців Сертифікатів. З огляду на
                це, Покупці Сертифікатів можуть звернутися до таких
                Корпоративних клієнтів з метою реалізації своїх прав, що описані
                у Розділі 5 цієї Політики. Інформація про Корпоративних клієнтів
                доступна для ознайомлення на відповідних Вебсторінках
                Корпоративних клієнтів на Вебсайті.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              Корпоративні клієнти (або фізичні особи, які діють від їхнього
              імені)
            </td>
            <td>
              <p>
                <b>
                  Для того, щоб надати вам можливість створити Профіль на нашому
                  Вебсайті та надалі використовувати Послуги Upfront
                </b>
                , ми збираємо та обробляємо ім’я та прізвище Корпоративного
                клієнта (його представника), а також адресу електронної пошти,
                контактний номер телефону та назву компанії Корпоративного
                клієнта (де застосовно).
              </p>
              <p>
                Такі персональні дані також можуть використовуватися{' '}
                <b>
                  з метою комунікації з вами щодо Послуг Upfront чи надання
                  технічної підтримки
                </b>
                .
              </p>
              <p>
                Корпоративний клієнт може у будь-який момент відредагувати дані
                у своєму профілі. У разі таких змін Upfront не зберігатиме старі
                дані, і вони будуть остаточно видалені з технічних записів
                роботи Вебсайту протягом трьох місяців.
              </p>
              <p>
                Крім цього,{' '}
                <b>
                  аби надати Покупцям Сертифікатів усю необхідну інформацію про
                  Корпоративного клієнта як емітента Сертифікатів, його
                  Продукцію та Умови випущених ним Сертифікатів
                </b>
                , ми збираємо та обробляємо контактні номер телефону та адресу
                електронної пошти Корпоративного клієнта, а також адресу його
                місця розташування.
              </p>
              <p>
                <b>Зверніть увагу</b>, що адреса місця розташування, контактні
                номер телефону та адреса електронної пошти будуть опубліковані
                та доступні для необмеженого кола осіб на Вебсторінці
                Корпоративного користувача, допоки Вебсторінка буде активною.
                Видалення Вебсторінки відбувається за індивідуальним запитом
                Корпоративного клієнта.
              </p>
            </td>
          </tr>
          <tr>
            <td>Усі Користувачі</td>
            <td>
              <p>
                <b>
                  Для того, щоб покращити та оптимізувати роботу нашого
                  Вебсайту, а також для того, щоб зрозуміти ваші потреби та
                  надавати послуги та рекламу, релевантну для вас
                </b>
                , ми можемо збирати ваші користувацькі дані. Користувацькі дані
                відображають вашу поведінку на нашому Вебсайті, наприклад, такі
                дані показують, які сторінки ви переглядали та як довго. Ми
                також можемо збирати параметри IP-адреси, інформацію про джерело
                трафіку, пристрій та операційну систему, роздільну здатність
                екрану, тривалість сесії, ваше місцезнаходження.
              </p>
              <p>
                Серед іншого користувацькі дані можуть збиратися за допомогою
                файлів cookies.
              </p>
              <p>
                Також коли ви звертаєтесь до нас із будь-яким запитом через
                Вебсайт або нашу електронну пошту, ми зберігаємо та обробляємо
                усі персональні дані, які ви самостійно нам надаєте у такий
                спосіб, для того,{' '}
                <b>
                  щоб надавати вам вичерпну відповідь на ваш запит та
                  переконатися, що ви задоволені нашими Послугами
                </b>
                .
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h2>3. Підстави для обробки персональних даних</h2>
      <p>
        У випадках, коли персональні дані обробляються з метою надання вам
        послуг на ваш індивідуальний запит, Upfront оброблятиме такі дані на
        підставі{' '}
        <Link to={ROUTES.AGREEMENT} target="_blank" rel="noopener noreferrer">
          договору
        </Link>{' '}
        з вами.
      </p>
      <p>
        В інших випадках ми попросимо у вас згоду на збір та обробку ваших
        персональних даних.
      </p>
      <p>
        Ми також можемо обробляти ваші персональні дані для захисту законного
        інтересу Upfront (наприклад, захисту від претензій та звернень до суду,
        державних органів чи інших осіб).
      </p>
      <h2>4. Хто має доступ до персональних даних? </h2>
      <p>
        Для того, щоб надавати вам якісні послуги, Upfront наймає працівників та
        залучає третіх осіб, які можуть отримувати доступ до ваших персональних
        даних. Третіми особами, що можуть отримувати доступ до ваших
        персональних даних є:
      </p>
      <p>
        <b>Незалежні підрядники</b>, у тому числі фізичні особи-підприємці, яких
        ми залучаємо до надання послуг Upfront, зокрема розробки Вебсайту та
        технічної підтримки.
      </p>
      <p>
        Компанія <b>Amazon Web Services, Inc.</b> та афілійовані з нею юридичні
        особи (<b>«AWS»</b>), яка надає нам послуги хостингу та на чиїх серверах
        ми зберігаємо усі наші дані, у тому числі персональні дані наших
        Користувачів. Сервери, на яких зберігаються дані Вебсайту, розташовані у
        м. Франкфурт, Німеччина.
      </p>
      <p>
        Компанія <b>Google LLC</b> та афілійовані з нею юридичні особи (
        <b>«Google»</b>), яка надає нам послуги сервісу Google Analytics. За
        допомогою цього сервісу ми можемо збирати та аналізувати дані про те, як
        використовується наш Вебсайт, щоб покращити його роботу та зробити наші
        послуги більш зручними та корисними для вас.
      </p>
      <p>
        Компанія <b>JivoSite Inc.</b> та афілійовані з нею юридичні особи, яка
        надає нам послуги сервісу <b>JivoChat</b>. Цей сервіс уможливлює роботу
        онлайн-чату на Вебсайті, через який ви можете зв’язатися із нашою
        службою підтримки.
      </p>
      <p>
        Крім цього, ми використовуємо послуги платіжної системи Liqpay,
        власником якої є акціонерне товариство комерційний банк{' '}
        <b>«Приватбанк»</b>. Ця система дозволяє нам проводити оплату за
        Сертифікати або за наші послуги, які ми надаємо через Вебсайт.{' '}
        <b>
          Водночас звертаємо вашу увагу на те, що Upfront не має доступу до
          фінансової інформації своїх Користувачів. Обробка такої інформації
          здійснюється лише у рамках системи Liqpay
        </b>
        .
      </p>
      <p>
        Наостанок, дані Покупців Сертифікатів є доступними також для{' '}
        <b>Корпоративних клієнтів Upfront</b>, що є емітентами відповідних
        Сертифікатів. Корпоративні клієнти можуть використовувати такі дані для
        того, щоб комунікувати із Покупцями Сертифікатів, вести бізнес-аналітику
        та звітність, а також керувати випущеними Сертифікатами.
      </p>
      <h2>5. Які права має Користувач?</h2>
      <p>
        Будь-яка фізична особа має визначені законом права щодо своїх
        персональних даних, і Upfront поважає їх. Як суб’єкт персональних даних,
        ви маєте право:
      </p>
      <ol type="a">
        <li>
          знати про джерела збирання, місцезнаходження своїх персональних даних,
          мету їхньої обробки, місцезнаходження або місце проживання володільця
          чи осіб, які від імені володільця оброблять персональні дані. Ви також
          можете дати доручення щодо отримання цієї інформації іншим особам;
        </li>
        <li>
          отримувати інформацію про умови надання доступу до персональних даних,
          зокрема інформацію про третіх осіб, яким передаються такі персональні
          дані;
        </li>
        <li>на доступ до своїх персональних даних;</li>
        <li>
          надіслати Upfront запит на отримання інформації про те, чи
          обробляються ваші персональні дані, а також на доступ до таких
          персональних даних, що обробляються, та отримати відповідь протягом 30
          календарних днів з дня, коли ми отримаємо ваш запит;
        </li>
        <li>
          пред’являти Upfront вмотивовану вимогу із запереченням проти обробки
          своїх персональних даних;
        </li>
        <li>
          пред'являти Upfront вмотивовану вимогу щодо зміни або знищення своїх
          персональних даних, якщо ці дані обробляються незаконно чи є
          недостовірними;
        </li>
        <li>
          на захист своїх персональних даних від незаконної обробки та
          випадкової втрати, знищення, пошкодження у зв'язку з умисним
          приховуванням, ненаданням чи несвоєчасним їх наданням, а також на
          захист від надання відомостей, що є недостовірними чи ганьблять вашу
          честь, гідність та ділову репутацію;
        </li>
        <li>
          звертатися зі скаргами на обробку своїх персональних даних до
          Уповноваженого Верховної Ради України із прав людини або до суду;
        </li>
        <li>
          застосовувати засоби правового захисту в разі порушення законодавства
          про захист персональних даних;
        </li>
        <li>
          вносити застереження стосовно обмеження права на обробку своїх
          персональних даних під час надання згоди;
        </li>
        <li>відкликати згоду на обробку персональних даних;</li>
        <li>знати механізм автоматичної обробки персональних даних;</li>
        <li>
          на захист від автоматизованого рішення, яке має для вас правові
          наслідки.
        </li>
      </ol>
      <p>
        Щоб реалізувати перелічені вище права, ви можете надіслати запит на нашу
        електронну пошту:{' '}
        <a href="mailto:data@upfront.com.ua">data@upfront.com.ua</a>. Ми
        старатимемось відповісти якнайшвидше. Ми також повідомимо вам, якщо ваш
        запит вимагає багато часу на обробку. Upfront не буде брати оплату за
        ваш запит. Задля захисту ваших персональних даних ми можемо вимагати від
        вас пройти ідентифікацію перед тим, як розкривати дані, щодо яких
        надійшов запит.
      </p>
      <h2>6. Безпека персональних даних</h2>
      <p>
        Ми вживаємо належних заходів безпеки, щоб захистити ваші персональні
        дані від випадкових втрати або знищення, від незаконної обробки,
        незаконного знищення чи доступу до них. Зокрема, ми створюємо резервні
        копії ваших даних, а також використовуємо безпечний https протокол для
        комунікації, використовуємо надійні паролі та надаємо доступ до
        персональних даних обмеженому колу осіб та лише у випадку необхідності
        (наприклад, коли це потрібно для надання послуг, передбачених{' '}
        <Link to={ROUTES.AGREEMENT} target="_blank" rel="noopener noreferrer">
          Публічною офертою Upfront
        </Link>
        , виконання умов цієї Політики, для захисту законних інтересів Upfront,
        Користувачів чи третіх осіб тощо).
      </p>
      <h2>7. Файли cookies</h2>
      <p>
        Cookies – це невеликі шматочки коду, що залишаються у вашому пристрої
        після того, як ви відвідали якийсь вебсайт. Ці шматочки дають можливість
        серверу надавати вам інформацію, індивідуалізовану під ваші потреби,
        коли ви будете використовувати певну платформу чи вебсайт наступного
        разу. Надання вами згоди Upfront на використання файлів cookies робить
        наш Вебсайт більш ефективним, швидким та безпечним, а також покращує
        його функціонування.
      </p>
      <p>
        У будь-якому випадку, ви можете заблокувати, видалити, чи вимкнути
        зберігання файлів cookies на вашому пристрої, якщо ваш браузер дозволяє
        вам це зробити. Ці посилання можуть бути корисними для того, щоб ви
        могли вибрати найкращу опцію вашого браузера:
      </p>
      <p>
        <a
          href="https://support.microsoft.com/uk-ua/help/17442/windows-internet-explorer-delete-manage-cookies"
          target="_blank"
          rel="noopener noreferrer"
        >
          Для користувачів Internet Explorer
        </a>
        <br />
        <a
          href="https://support.google.com/chrome/answer/95647?hl=en&ref_topic=14666"
          target="_blank"
          rel="noopener noreferrer"
        >
          Для користувачів Chrome
        </a>
        <br />
        <a
          href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          target="_blank"
          rel="noopener noreferrer"
        >
          Для користувачів Safari web
        </a>{' '}
        та{' '}
        <a
          href="https://support.apple.com/en-us/HT201265"
          target="_blank"
          rel="noopener noreferrer"
        >
          iOS
        </a>
        .
      </p>
      <h2>8. Зміни до цієї Політики приватності</h2>
      <p>
        Ми можемо час від часу змінювати нашу Політику приватності, оновлюючи
        дату опублікування у верхній частині цієї Політик. Подальше використання
        вами Вебсайту підтверджує вашу згоду з нашою Політикою. Якщо ви не
        згодні з умовами цієї Політики, будь ласка, припиніть використання
        Вебсайту. У будь-якому разі, якщо зміни до Політики є важливими для
        захисту вашого права на приватність, ми додатково запитаємо вашої згоди
        щодо умов оновленої версії Політики.
      </p>
      <p>
        <b>Контактна інформація</b>
        <br />
        <b>ФОП Чиж Роман Вікторович</b>,<br />
        <br />
        Адреса: 02088, м. Київ, вул. Садова, 8, буд. 18
        <br />
        <a href="mailto:upfront.com.ua@gmail.com">upfront.com.ua@gmail.com</a>
      </p>
    </div>
  );
}
